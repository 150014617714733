import { defineStore } from 'pinia';

export interface CurrentProperty {
    pk: number;
}

export const usePropertiesStore = defineStore('properties', {
    state: () => ({
        current: null as CurrentProperty | null,
    }),
    getters: {},
    actions: {
        async getCurrentProperty() {
            // We check if we are in the development environment (localhost) and return prematurely
            // to avoid a call to es-site
            // TODO: IDR-475 - move this to Axios Interceptor.
            if (process.env.NODE_ENV === 'development') return;

            const response = await this.$nuxt?.$axios.get('/market/consumers/api/v1/properties/current/');
            this.current = response?.data;
        },
    },
});
