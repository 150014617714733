import { type AxiosRequestConfig } from 'axios';
import type { Route } from 'vue-router';
import { INTERNAL_SERVER_ERROR, EIAIDS_WITH_STORAGE_SAVINGS } from '@/store/constants';
import { LOGGED_IN_STEPS } from '~/constants';

export const AXIOS_OPTIONS: AxiosRequestConfig = {
    validateStatus: (status) => status < INTERNAL_SERVER_ERROR,
};

export const getMaxStorageSavings = (energyInformationAdministrationIDs: number[]): number | null => {
    const storageSavings = EIAIDS_WITH_STORAGE_SAVINGS.filter(({ eiaid }) =>
        energyInformationAdministrationIDs.includes(eiaid),
    )?.map(({ storage_savings_percent }) => storage_savings_percent);
    return storageSavings?.length ? Math.max(...storageSavings) : null;
};

export const routeRequiresLogin = (route: Route) => LOGGED_IN_STEPS.includes(route.name as string);
